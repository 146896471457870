@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
body > div:first-child,
div#__next {
  height: 100%;
}

.firebase-emulator-warning {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 98%;
    --foreground: 192 100% 14%;
    --muted: 0 0% 95%;
    --muted-foreground: 194 43% 33%;
    --popover: 0 0% 100%;
    --popover-foreground: 192 100% 14%;
    --border: 0 0% 83%;
    --input: 0 0% 83%;
    --card: 0 0% 100%;
    --card-foreground: 192 100% 14%;
    --primary: 171 95% 23%;
    --primary-foreground: 137 47% 97%;
    --primary-foreground-50: 144 56% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 192 100% 14%;
    --accent: 137 47% 97%;
    --accent-foreground: 171 95% 23%;
    --destructive: 18 100% 50%;
    --destructive-foreground: 0 86% 97%;
    --warning: 240 88% 62%;
    --warning-foreground: 18 100% 50%;
    --attention: 234 77% 95%;
    --attention-foreground: 240 86% 62%;
    /* We want to manage a non-transparent muted colour to avoid opacity overlap */
    --attention-75: 236 79% 96%;
    --attention-50: 240 100% 98%;
    --ring: 240 88% 62%;
    --radius: 0.5rem;
    /* Bottom bar */
    --bottom-bar: 195 45% 92%;
    --bottom-bar-foreground: 192 100% 14%;
    --sidebar-background: 0 0% 95%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --shadow: rgba(0, 0, 0, 0.05);
  }

  .dark {
    --background: 196 92% 10%;
    --foreground: 195 58% 96%;
    --muted: 194 75% 8%;
    --muted-foreground: 195 58% 96%;
    --accent: 245 82% 15%;
    --accent-foreground: 232 100% 87%;
    --popover: 224 71% 4%;
    --popover-foreground: 159 100% 50%;
    --border: 194 47% 26%;
    --input: 194 47% 26%;
    --card: 196 92% 10%;
    --card-foreground: 159 100% 50%;
    --primary: 159 100% 50%;
    --primary-foreground: 192 100% 14%;
    --primary-foreground-50: 193 97% 12%;
    --warning-foreground: 15 100% 81%;
    --secondary: 192 100% 14%;
    --secondary-foreground: 195 58% 96%;
    --attention: 245 82% 15%;
    --attention-foreground: 232 100% 87%;
    /* We want to manage a non-transparent muted colour to avoid opacity overlap */
    --attention-75: 245 61% 19%;
    --attention-50: 244 47% 24%;
    --destructive: 12 100% 19%;
    --destructive-foreground: 15 100% 98%;
    --ring: 241 92% 69%;
    --bottom-bar: 196 100% 14%;
    --bottom-bar-foreground: 195 48% 26%;

    --sidebar-background: 194 75% 8%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply font-sans antialiased bg-background text-foreground;
  }
}

#HW_badge_cont {
  position: absolute;
  right: 2px;
  top: -3px;
}

#HW_badge {
  background: #ff4d00;
  color: transparent;
  transform: scale(0.65) translate3d(20%, -10%, 0);
}

#HW_badge.HW_softHidden {
  visibility: hidden;
}

:root {
  --chart-navy-20: #003846;
  --chart-navy-50: #4f889b;
  --chart-navy-80: #bbd4dd;

  --chart-green: #00ffa4;
  --chart-green-50: #019a79;

  --chart-purple-80: #bfc7ff;
  --chart-purple-40: #4949f1;
  --chart-purple-50: #6a68f9;
  --chart-purple-10: #0c0746;

  --chart-neutral-70: #d4d4d4;

  --chart-yellow-70: #fee300;

  --chart-orange-70: #fa8b65;
}

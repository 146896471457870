@import "@packfleet/ui/dist/index.css";

.pulser {
  animation: pulser ease-in-out 5s infinite;
  transform-origin: 0 0;
}

.animation-scaledFadeIn {
  animation-name: scaledFadeIn;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
}

@keyframes scaledFadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animation-slideDown {
  animation-name: slideDown;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animation-slideInUp {
  animation-name: slideInUp;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
}

.animation-slideInUpSm {
  animation-name: slideInUpSm;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
}

.animation-slideInUpXs {
  animation-name: slideInUpXs;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUpSm {
  from {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUpXs {
  from {
    opacity: 0;
    transform: translate3d(0, 8px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animation-pushInRight {
  animation-name: pushInRight;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
}

@keyframes pushInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animation-pushOutRight {
  animation-name: pushOutRight;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
}

@keyframes pushOutRight {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes pulser {
  0% {
    transform: scale(0.8) translateX(-50%) translateY(-50%);
  }
  50% {
    transform: scale(1) translateX(-50%) translateY(-50%);
  }
  100% {
    transform: scale(0.8) translateX(-50%) translateY(-50%);
  }
}

:root {
  --puck-side-bar-width: 200px;
  --puck-top: 54px;
}

/* Padding to account for the main nav bar + sidebar */
div[class*="_Puck_"] {
  --puck-color-white: hsl(var(--background));
  --puck-color-black: hsl(var(--foreground));
  --puck-color-grey-3: hsl(var(--muted-foreground));
  --puck-color-grey-8: hsl(var(--border));
  --puck-color-grey-7: hsl(var(--border));
  --puck-color-grey-4: hsl(var(--muted-foreground));
  --puck-side-bar-width: 200px;
  --shadow: rgba(0, 0, 0, 0.05);
  top: var(--puck-top) !important;
  left: calc(var(--puck-side-bar-width) + 64px) !important;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  border-left: 1px solid var(--puck-color-grey-8);
  overflow: hidden;
  border-top-left-radius: 10px;
  background-color: hsl(var(--background));
  grid-template-columns: var(--puck-side-bar-width) var(--puck-frame-width) 240px !important;
}

.dark div[class*="_Puck_"] {
  --shadow: rgba(255, 255, 255, 0.1);
}

div[class*="_Puck_"] {
  font-size: 12px;
  --puck-font-size-xs-unitless: 14;
  --puck-font-size-xs: 12px;

  /* Sidebar background color */
  --puck-color-grey-11: hsl(var(--background));

  /* Root background color */
  --puck-color-grey-9: hsl(var(--background));

  /* Draggable component */
  --puck-font-size-xxs: 12px;
}

div[class*="_Puck_"] > div {
  /* Sidebar border color */
  --puck-color-grey-8: transparent;
}

div[class*="_Puck_"] > div details {
  --puck-color-grey-8: #dcdcdc;
}

div[class*="_Puck_"] > div details fieldset {
  padding-left: 16px;
  padding-right: 16px;
}

div[class*="_Puck_"] div[class*="_SidebarSection-"]:last-of-type {
  padding-bottom: 64px;
}

div[class*="_Puck_"] div[class*="_SidebarSection-"] > div[class*="_Input_"] {
  padding-bottom: 0;
}

div[class*="_Puck_"]
  div[class*="_SidebarSection-"]
  > div[class*="_Input_"]:empty {
  display: none;
}

div[class*="_Puck_"] div[class*="_SidebarSection-title"] {
  background: transparent;
  border-bottom: 0;
  opacity: 0.3;
  margin-bottom: -16px;
}

div[class*="_Puck_"] div[class*="_SidebarSection-content"] {
  padding-top: 8px !important;
  padding-right: 0;
  border-bottom: 0 none;
}

div[class*="_Puck_"] div[class*="_ComponentList-title_"] {
  padding-left: 12px;
  font-size: 10px;
  opacity: 0.7;
  letter-spacing: 0.5px;
}

div[class*="_Puck_"] div[class*="_ComponentListItem_"] {
  background-color: transparent;
  opacity: 0.5;
  --puck-color-grey-8: #dcdcdc;
}

div[class*="_Puck_"] div[class*="_ComponentListItem_"]:hover {
  opacity: 1;
}

div[class*="_Puck_"] div[class*="_ComponentListItem-draggable"] {
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
  --puck-color-azure-9: hsl(var(--primary-foreground));
  --puck-color-azure-4: hsl(var(--primary));
}

div[class*="_Puck_"]
  div[class*="_ComponentListItem"][data-rfd-draggable-id="Conditional Block"],
div[class*="_Puck_"]
  div[class*="_ComponentListItem"][data-rfd-draggable-id="Time Slot Options"],
div[class*="_Puck_"]
  div[class*="_ComponentListItem"][data-rfd-draggable-id="Time Slot Receipt"] {
  color: #6550b9;
  --puck-color-grey-4: #6550b9;
  --puck-color-azure-9: #f4f0fe;
  --puck-color-grey-8: #d4cafe;
  --puck-color-azure-4: #6550b9;
}

div[class*="_Puck_"]
  div[class*="_ComponentListItem"][data-rfd-draggable-id="Time Slot Options"] {
  margin-top: 24px;
}

div[class*="_Puck_"] div[class*="_ComponentListItem_"]:hover {
  background-color: hsl(var(--background));
}

div[class*="_Puck_"] div[class*="_Input-label_"],
div[class*="_Puck_"] b[class*="_Input-label_"] {
  font-weight: 300;
  text-transform: capitalize;
  padding-bottom: 8px;
}

div[class*="_Puck_"] div[class*="_Input-label_"] > div,
div[class*="_Puck_"] b[class*="_Input-label_"] > div {
  display: none;
}

div[class*="_Puck_"] div[class*="_SidebarSection-"] input,
div[class*="_Puck_"] div[class*="_SidebarSection-"] select,
div[class*="_Puck_"] div[class*="_SidebarSection-"] textarea {
  --puck-color-grey-8: #dcdcdc;
  background-color: hsl(var(--background));
  border: 1px solid hsl(var(--border));
  padding: 8px;
  font-size: 12px;
}

div[class*="_Puck_"] div[class*="_Input-radioInner"] {
  background-color: hsl(var(--input));
  color: hsl(var(--input-foreground));
}

div[class*="_Puck_"] div[class*="_Input-radioGroupItems_"] {
  border-color: hsl(var(--border));
}

div[class*="_Puck_"] div[class*="_ArrayFieldItem-summary_"] {
  --puck-color-grey-8: #dcdcdc;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

div[class*="_Puck_"]
  div[class*="_ArrayFieldItem--isExpanded_"]
  div[class*="_ArrayFieldItem-summary_"] {
  border-color: transparent;
}

div[class*="_Puck-root_"] {
  max-width: 600px;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 0 !important;
  box-shadow: 0 0 16px var(--shadow) !important;
}

/* Hide Outline in sidebar */
div[class*="_Puck_"] > div > div[class*="_SidebarSection_"]:nth-child(2) {
  display: none;
}

/* Hide the "Page" title and breadcrumb */
div[class*="_Puck_"] div[class*="_SidebarSection-title"] h2,
div[class*="_Puck_"] div[class*="_SidebarSection-breadcrumbs_"],
div[class*="_Puck_"] div[class*="_SidebarSection-breadcrumbs_"] + svg {
  display: none;
}

div[class*="_Puck_"] div[class*="_SidebarSection"] svg + h2 {
  display: block;
  padding-top: 8px;
}

div[class*="_Puck_"] div[class*="_DraggableComponent"] {
  --puck-color-grey-8: #dcdcdc;
}

div[class*="_Puck_"] div[class*="_DraggableComponent"] svg {
  width: 16px;
  height: 16px;
}

.packfleet-pro-container {
  --puck-color-grey-10: transparent;
}

.packfleet-pro-container .packfleet-pro-embed {
  display: none;
}

.packfleet-pro-container .packfleet-pro-sms {
  display: none;
}

.packfleet-pro-container[data-display="embed"] .packfleet-pro-embed {
  display: flex;
}

.packfleet-pro-container[data-display="sms"] .packfleet-pro-sms {
  display: flex;
}

.packfleet-pro-container[data-display="embed"] .packfleet-pro-email,
.packfleet-pro-container[data-display="sms"] .packfleet-pro-email,
.packfleet-pro-container[data-display="embed"] .packfleet-pro-email-template,
.packfleet-pro-container[data-display="sms"] .packfleet-pro-email-template,
.packfleet-pro-container[data-display="embed"] .puck-root-fields,
.packfleet-pro-container[data-display="sms"] .puck-root-fields,
.packfleet-pro-container[data-display="embed"] .puck-fields,
.packfleet-pro-container[data-display="sms"] .puck-fields {
  display: none;
}

.packfleet-pro-container[data-display="mobile"] .packfleet-pro-email-template {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}
